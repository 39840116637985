<template>
  <div id="app" class="app-body">
    <div>
      <div class="spacer"></div>

      <div class="columns is-vcentered">
        <div class="column">
          <p class="is-title is-secondary is-bold">
            Login to your <br /><span class="is-primary">Brandeeq</span>
            Workspace
          </p>
          <b-message class="mt-4" type="is-info">
            This login is for Brandeeq Team Accounts only
          </b-message>
          <form @submit.prevent="login" id="form">
            <div class="py-2">
              <b-field label="E-mail">
                <b-input v-model="email" type="email"></b-input>
              </b-field>
              <b-field label="Password">
                <b-input
                  v-model="password"
                  type="password"
                  password-reveal
                ></b-input>
              </b-field>
            </div>
            <button
              form="form"
              class="button is-info"
              type="submit"
              :disabled="isLogginIn"
            >
              <i class="mdi mdi-check mr-1"></i>
              {{ isLogginIn ? "Logging In" : "Log In" }}
            </button>
          </form>
        </div>
        <div class="column"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      email: undefined,
      password: undefined,
      isLogginIn: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  created() {
    if (this.currentUser && this.currentUser.type) {
      if (this.currentUser.type == "vendor") {
        this.$router.push({ name: "VendorDashboard" });
      }
    }

    if (this.currentUser && this.currentUser.type) {
      if (this.currentUser.type == "customer") {
        this.$router.push({ name: "Dashboard" });
      }
    }
  },
  methods: {
    async login() {
      this.isLogginIn = true;
      await this.$store.dispatch("vendorLogin", {
        email: this.email,
        password: this.password,
      });
      this.isLogginIn = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
